import Shows from "./Shows";

export default function Home() {
  return (
    <section className="home">
      <hr />
      <h2>
        NEW RECORD: "City Lights"{" "}
        <a
          href="https://thesewimps.bandcamp.com/album/city-lights"
          rel="noreferrer"
          target="_blank"
          className="link"
        >
          Buy Now!
        </a>
      </h2>
      {/* MOM */}
      <iframe
        width="560"
        title="MOM"
        height="315"
        src="https://www.youtube.com/embed/fZa-0SNx2pk"
        allowFullScreen
      ></iframe>
      {/* LAKE WASHINGTON */}
      <iframe
        width="560"
        title="MOM"
        height="315"
        src="https://www.youtube.com/embed/yp6MWwsoLnQ"
        allowFullScreen
      ></iframe>
      <Shows home />
    </section>
  );
}
