export default function Contact() {
    return (
      <div className="contact">

        <figure>
          <img src="https://thesewimps.com/shared/Press%20Photos/Digital/wimpsPressPhoto_WithKurtbyKayRedden.jpg" alt="Wimps with Kurt" />
          <figcaption>photo credit: Kay Redden</figcaption>
        </figure>

        <h2>Bio:</h2>

        <p>Wimps are a Seattle-based three-piece led by Rachel Ratner (vocals / guitar), Matt Nyce (bass / backing vocals), and Dave Ramm ( drums ).</p>

        <p>Wimps play rapid-fire punk songs (with a smattering of angular lo-fi post punk) with shout-sung lyrics about common day-to-day woes such as insomnia, anxiety and procrastination.</p>

        <h2>Booking / Contact:</h2>
        <a href="mailto:wimpsmail@gmail.com">wimpsmail@gmail.com</a>
        
  
        <h2>Social Media:</h2>
        <ul>
          <li><a href="https://www.facebook.com/thesewimps">Facebook</a></li>
          <li><a href="https://www.facebook.com/thesewimps">Twitter</a></li>
          <li><a href="https://instagram.com/wimpsgram">Instagram</a></li>
          <li><a href="https://soundcloud.com/thesewimps">Soundcloud</a></li>
          <li><a href="https://www.youtube.com/channel/UC79fq2im1PLYP4GLQ1_QZsg">Youtube</a></li>
          <li><a href="https://thesewimps.bandcamp.com">Bandcamp</a></li>
        </ul>

        <h2>Press Photos:</h2>

          <figure>
          <img src="http://thesewimps.com/shared/Press%20Photos/Digital/WIMPS--4x6%20JPG--36_misfitsbyNathanPalace.jpg" alt="wimps looking like the misfits" />
          <figcaption>photo credit: Nathan Pallace</figcaption>
        </figure>

        <figure>
          <img src="http://media2.fdncms.com/inlander/imager/u/original/2546710/music2-1-855131dc1f326aef.jpg" alt="Wimps at KEXP" />
          <figcaption>photo credit: Victoria Holt</figcaption>
        </figure>

        <figure>
          <img src="https://farm6.staticflickr.com/5661/21128105779_8050e5032c_z.jpg" alt="Wimps sitting on a couch" />
          <figcaption>photo credit: Matthew B. Thompson</figcaption>
        </figure>

        <h2>Past Press</h2><ul>
        <li><a href="https://www.seattletimes.com/entertainment/music/playful-seattle-punks-wimps-get-serious-well-sometimes-on-colorful-new-album-garbage-people/">Seattle Times 2018</a></li>
        <li><a href="https://www.inlander.com/music/as-they-enter-their-40s-the-members-of-seattle-trio-wimps-go-back-to-their-fast-fun-punk-roots-11683097">Inlander, 2018</a></li>
        <li><a href="https://kexp.org/read/2018/7/12/throwaway-style-interview-rachel-ratner-wimps/">KEXP Throwaway Style, 2018</a></li>
        <li><a href="http://post-trash.com/news/2018/5/23/wimps-giant-brain-post-trash-premiere">Post Trash Premiere</a></li>

          <li><a href="https://www.punkrocktheory.com/music_reviews/wimps-garbage-people">Garbage People, Punk Rock Theory</a></li>
          <li><a href="http://www.avclub.com/article/wimps-unpacks-its-suitcase-new-track-226098">Wimps Unpacts it's Suitcase, A.V. Club</a></li>
          <li><a href="https://bitchmedia.org/article/new-music-monday-wimps">Bitch Media</a></li>
          <li><a href="http://www.seattleweekly.com/home/960027-129/exclusive-the-secret-lives-of-punk">Best Punk Band 2015, Seattle Weekly</a></li>
          <li><a href="http://noisey.vice.com/blog/wimps-are-anything-but-on-new-ep">"Super Me" Review, Noisey/Vice, 2015</a></li>
          <li><a href="http://www.thisamericanlife.org/radio-archives/episode/516/stuck-in-the-middle">This American Life, featured song</a></li>
          <li><a href="http://pitchfork.com/reviews/albums/17594-repeat/">"Repeat" Review, Pitchfork, 2013</a></li>
          <li><a href="http://www.thestranger.com/seattle/sweet-n-sour-punk/Content?oid=16232149">Sweet n Sour Punk, The Stranger, 2013</a></li>
        </ul>
      </div>
    );
  }
  