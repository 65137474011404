import { Outlet, Link, NavLink } from "react-router-dom";
import logo from "./img/hotdoglogo.jpg";
import cityLights from "./img/BandcampCover.jpg";

export default function Layout() {
  const linkStyle = (isActive) => ({
    textDecoration: isActive ? "underline" : "none",
  });
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
            share across all the pages on your site, like navigation. */}
      <header>
        <nav>
          <ul>
            <li>
              <NavLink to="/" style={({ isActive }) => linkStyle(isActive)}>
                Home
              </NavLink>
            </li>
            <li>
              <Link
                to="https://thesewimps.bandcamp.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                Store
              </Link>
            </li>
            <li>
              <NavLink
                to="/videos"
                style={({ isActive }) => linkStyle(isActive)}
              >
                Videos
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/shows"
                style={({ isActive }) => linkStyle(isActive)}
              >
                Shows
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                style={({ isActive }) => linkStyle(isActive)}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
        <a
          target="_blank"
          href="https://thesewimps.bandcamp.com/album/city-lights"
          rel="noopener noreferrer"
        >
          <img src={cityLights} alt="city scape art" />
        </a>
      </header>

      <Outlet />
      <footer>
        © wimps, {new Date().getFullYear()}
        <br />
        <div>
          <img className="hotdog" alt="hotdog" src={logo} />
        </div>
      </footer>
    </div>
  );
}
