
import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from './Layout';
import Home from './Home';
import Videos from './Videos';
import Contact from './Contact';
import ShowArchive from './ShowArchive';
import NoMatch from "./NoMatch";
import Shows from "./Shows";

export default function App() {
  return (
    <div className="page">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="videos" element={<Videos />} />
          <Route path="contact" element={<Contact />} />
          <Route path="pastshows" element={<ShowArchive />} />
          <Route path="shows" element={<Shows />} />

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}
