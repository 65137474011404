export default function Videos() {
    return (
      <div>
          {/* MOM */}
          <iframe width="560" title="MOM" height="315" src="https://www.youtube.com/embed/fZa-0SNx2pk" allowFullScreen></iframe>
          {/* LAKE WASHINGTON */}
          <iframe width="560" title="MOM" height="315" src="https://www.youtube.com/embed/yp6MWwsoLnQ" allowFullScreen></iframe>
          {/* INSOMNIA */}
          <iframe width="560" title="insomnia" height="315" src="https://www.youtube.com/embed/yxhfLFEDfIc" allowFullScreen></iframe>
          {/* GIANT BRAIN */}
          <iframe width="560" title="giant brain" height="315" src="https://www.youtube.com/embed/Z0g0Vaufqxg" allowFullScreen></iframe>
          {/* OLD GUY */}
        <iframe width="560" title="old guy" height="315" src="https://www.youtube.com/embed/W4EhXRX-ti0" allowFullScreen></iframe>
          {/* COUCHES */}
        <iframe width="560" title="couches" height="315" src="https://www.youtube.com/embed/l1wDf6-T6Yg" allowFullScreen></iframe>
          {/* DUMP */}
        <iframe width="560" title="dump" height="315" src="https://www.youtube.com/embed/mwZ_TXL-aUg" allowFullScreen></iframe>
          {/* REPEAT */}
        <iframe width="560" title="repeat" height="315" src="https://www.youtube.com/embed/3QU7fF42hGk" allowFullScreen></iframe>
          {/* PARTY AT THE WRONG TIME */}
        <iframe width="560" title="party at the wrong time" height="315" src="https://www.youtube.com/embed/G6HzjC46Fec" allowFullScreen></iframe>
          {/* DOG PILLS */}
        <iframe width="560" title="dog pills" height="315" src="https://www.youtube.com/embed/94PCLzP5Xzg" allowFullScreen></iframe>
          {/* STOP HAVING FUN (LIVE AT KEXP) */}
        <iframe width="560" title="stop having fun" height="315" src="https://www.youtube.com/embed/lFVoBg_X9_A" allowFullScreen></iframe>
          {/*  HELLO FRUSTRATION */}
        <iframe width="560" title="hello frustration" height="315" src="https://www.youtube.com/embed/44vt-wAHh5I" allowFullScreen></iframe>
          {/*  NAP */}
        <iframe width="560" title="nap" height="315" src="https://www.youtube.com/embed/hAcQfkWqTFs" allowFullScreen></iframe>
      </div>
    );
  }
