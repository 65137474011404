export default function ShowArchive() {
  return (
    <div className="show-archive">
      <h2>Show Archive</h2>
      <h3>2023</h3>
      <ul>
        <li>9/2 @ Bumbershoot</li>
        <li>6/24 @ Wallingford Porchfest</li>
        <li>5/28 @ Tractor Tavern w/ Soft Boiled, Catch Rabbit</li>
      </ul>

      <h3>2022</h3>
      <ul>
        <li>8/14 @ Seward Park Ampitheater w/ Tissue</li>
        <li>7/18 @ Add a Ball Records</li>
      </ul>

      <h3>2019</h3>
      <ul>
        <li>7/19 @ Cha Cha (Capitol Hill Block Party)</li>
        <li>4/20 @ The Liquor Store, w Clarke and the Himselfs</li>
        <li>3/1 @ Southgate Roller Rink w/ Dyed, the Middle Ages</li>
      </ul>

      <h3>2018</h3>
      <ul>
        <li>12/12 @ Highline w/ Dyed, Clean Lines</li>
        <li>11/09 @ Off Beat Music Fest (Reno)</li>
        <li>11/03 @ Central Saloon w/ Lithics, Courtney and the Crushers</li>
        <li>10/06 @ Rolling Bay Hall w/ Happy Times Sad Times</li>
        <li>9/29 @ Modest Music Fest (Moscow, ID)</li>
        <li>9/21 @ Sunset w/ Savak, Hurry Up</li>
        <li>8/31 @ Bumbershoot w/ Ludacris</li>
        <li>8/25 @ Camp Daze (Missoula)</li>
        <li>8/24 @ The Bartlett w/ Bad Motivators, Itchy Kitty</li>
        <li>8/11 @ Alphaville w/ Monograms, Human People, Patio</li>
        <li>
          8/10 @ Berlin w/ Ellen and the Degenerates, High Pony (
          <a href="https://nymag.com/arts/all/to-do-2018-08-06/">preview</a>)
        </li>
        <li>8/09 @ Boot & Saddle w/ Sky Farm, RunHideFight</li>
        <li>8/08 @ Black Cat (DC) w/ Bacchae</li>
        <li>8/07 @ Pinhook w/ Le Weekend</li>
        <li>8/06 @ Fond Objects Records (Nashville) w/ Peachy, Joybomb</li>
        <li>8/05 @ Pioneer (Indianapolis, IN) w/ Chives</li>
        <li>8/04 @ Milf Crate, Aurora w/ Draculas</li>
        <li>8/03 @ Beat Kitchen w/ Jollys, The Bingers, Speed Babes</li>
        <li>8/02 @ Soda Bar (SD) w/ Keepers, Miss New Buddha</li>
        <li>8/01 @ The Hi Hat (LA) w/ Maniac, Lunch Lady</li>
        <li>7/31 @ Hard to Find (Goleta, CA) with Sweet Reaper, Share</li>
        <li>7/30 @ Boo Boo Records (SLO)</li>
        <li>7/29 @ Crepe Place (Santa Cruz) with Preening</li>
        <li>
          7/28 @ Thee Parkside (SF) w/ Preening, States of Nature, Fuckwolf
        </li>
        <li>
          7/27 @ Cafe Colonial (Sacramento) w/ Woolen Men, Boy Romeo, Meiocre
          Cafe
        </li>
        <li>7/19 @ Sonic Boom</li>
        <li>7/14 @ Clockout with Sleepover Club, Fabulous Downy Brothers</li>
        <li>7/13 @ Mississippi studios with Ghost Ease, Moped Grooves</li>
        <li>5/12 @ St John's Bizarre (PDX)</li>
        <li>4/20 @ Lucky Liquor w/ Woolen Men, Versing, Xurs</li>
        <li>
          3/24 @ Linen (Boise, Id) w/ Horse Feathers, Kinski, Cindy Wilson
        </li>
        <li>3/23 @ The Observatory w/ Tissue, Kinski, Peru Resh</li>
        <li>2/25 @ Black Lodge w/ Tissue</li>
      </ul>

      <h3>2017</h3>
      <ul>
        <li>
          12/23 @ Hard Rain (Osaka, JP) w/ Boys Order, Alcohol Apology,
          manchester school≡, Foodie
        </li>
        <li>12/22 @ Pot (Nishiwaki, JP) w/ Fashion Keys</li>
        <li>
          12/21 @ Negi/Posi (Kyoto, JP) w/ Back To Basics, SANHOSE, Cosmicos
        </li>
        <li>12/19 @ Ripple (Nagoya, JP) w/ NicFit, Social Porks</li>
        <li>12/18 @ Fever (Tokyo, JP) w/ Noodles, Foodie</li>
        <li>12/17 @ Kirchherr (Hamamatsu, JP) w/ Foodie, Fashion Keys</li>
        <li>
          12/16 @ Helluva Lounge (Kobe, JP) w/ Antonio Three, Catholic
          Girlfriends
        </li>
        <li>10/13 @ Black Lodge w/ The Trashies, Clarko, The Carols</li>
        <li>9/14 @ Werewolf Vacation w/ Do Normaal, Mr. Wrong</li>
        <li>8/26 @ The Know (PDX) with Mascaras, Blesst Chest</li>
        <li>8/10 @ Pacific Science Center w/ Big Bite (Laser Wimps!)</li>
        <li>
          7/15 @ American Leigon (PDX) w/ Patsy's Rats, Hornet Leg, Mini Blinds,
          Conditioner w/
        </li>
        <li>6/24 @ Transitions (Tampa) w/ Seafang, NMBRS, Sallow</li>
        <li>
          6/23 @ Will's Pub (Orlando) w/ Tight Genes, Problem Packs, Radicalized
          Youth
        </li>
        <li>6/22 @ El Rocko Lounge (Savannah) w/ The Gumps</li>
        <li>6/21 @ The Earle (Atlanta) w/ Beiji</li>
        <li>6/20 @ Siberia (Nola) w/ Black Abba, Ghost Coast</li>
        <li>6/19 @ Walter's Downtown (Houston) w/ Clare, Bask</li>
        <li>6/18 @ Cheer Up Charlies (Austin) w/ Miranda, Nike</li>
        <li>6/3 @ Lucky Liquor with Woolen Men, Honey Bucket, Last Picks</li>
        <li>5/13 @ Upstream music fest</li>
        <li>4/9 @ Ren Fayre w/ Lithics</li>
        <li>4/8 @ Funhouse w/ Great Spiders, Feed</li>
        <li>
          2/23 @ Cha Cha, DIY benefit w/ Great Spiders, Dead Bars, Patsys Rats
        </li>
        <li>
          2/4 @ Vera Project for Girls That Shred benefit w/ Kimya Dawson,
          Acapulco Lips{" "}
        </li>
        <li>1/28 @ Timber Music Fest (Levenworth) w/ Thermals</li>
        <li>1/20 @ Barboza with Tacocat, Black Tones</li>
        <li>1/20 @ KEXP Give Peace a Chance Live Stream</li>
      </ul>

      <h3>2016</h3>

      <ul>
        <li>
          12/10 @ Spare Room for Kill Rock Stars Holiday Show w/ Kinski, Deep
          Fried Bookie Band
        </li>
        <li>
          11/4 @ Black Lodge for Punk the Vote w/ for Macefield Music Fest w/
          SSDD, Nail Polish, Malidont
        </li>
        <li>10/22 @ Eastern Oregon Film Festival</li>
        <li>10/1 @ Tractor for Macefield Music Fest</li>
        <li>8/20 @ The Observator (Spokane) w/ S1UGS, Peru Resh</li>
        <li>8/19 @ The Spare Room (PDX) w/ Kindski, Lithics</li>
        <li>8/18 @ Chop Suey w/ Kinski, Lithics</li>
        <li>7/11 @ The Crocodile w/ Boss Hog</li>
        <li>7/2 @ The Boreal (Eugene, OR) w/ VCR, Critical Shakes</li>
        <li>7/1 @ Thee Parkside (SF) w/ Violence Creeps, Baus, Mane</li>
        <li>6/30 @ Fatte Alberts Pizza Co (Hanford, CA) w/ Sci Fi Capers</li>
        <li>6/29 @ Soda Bar (SD) w/ w/ Katie Von Schleicher</li>
        <li>6/28 @ Ham and Eggs w/ Maniac(LA)</li>
        <li>6/27 @ Hot Tub Show with Kristen(LA)</li>
        <li>6/26 @ Press Club (Sacramento) w/ Grave Lake, Boy Romeo</li>
        <li>6/25 @ Holland Project (Reno) w/ Arizona Young, Moons of Vega</li>
        <li>
          6/24 @ American Legion (PDX) with Mope Grooves, Honey Bucket and
          Marcy's Band
        </li>
        <li>6/4 @ Bridgetown Comedy Fest w/ Thermals</li>
        <li>5/29 @ Sasquatch w/ The Cure, Mac Demarco</li>
        <li>5/27 @ Werewolf Vacation w/ Pony Time, Numerators</li>
        <li>5/14 @ Cockpit w/ SSDD, Dark/Light</li>
        <li>5/12 @ Neumos w/ The Thermals, Dude York</li>
        <li>4/24 @ Chop Suey w/ Pony Time, Night Boss</li>
        <li>4/9 @ Reeds (Chicago) w/ Today's Hits &amp; Bingers</li>
        <li>
          4/8 @ UFO Factory Barn (Detroid) w/ Brothels, Sneaky Creeps, Primativ
          Parts
        </li>
        <li>
          4/7 @ Silver Dollar Room (Toronto) w/ Hobo Lord, New Enchanters, &amp;
          Bards
        </li>
        <li>4/6 @ Makespace (Harrisburg) w/ Deletions</li>
        <li>
          4/5 @ Windup Space (Baltimore) w/ Ed Schrader's Music Beat, Jaill
          &amp; Abroad
        </li>
        <li>4/4 @ The Nest (Philadelphia) w/ Minor Setbacks, Forest Ranger</li>
        <li>4/3 @ Out of the Blue Too (Cambridge) w/ Gravel &amp; Half Sour</li>
        <li>4/2 @ Berlin (NY) w/ Jounce, Washer</li>
        <li>
          4/1 @ Silnent Barn (NY) Music Fest (ID) w/ WALL, Parlor Walls,
          Nonsense
        </li>
        <li>3/26 @ Treefort Music Fest (ID) w/ Built to Spill, others</li>
        <li>3/25 @ Black Lodge w/ Stickers, VHS, Casual Hex, Stucko, Tokyo</li>
        <li>
          2/19 @ Neumos w/ Moby (DJ Set), Grace Love and the True Loves. KEXP
          Benefit
        </li>
        <li>
          2/13 @ Obsidian (Oly) w/ Fabulous Downey Brothers, UK Gold, Falling
          Over
        </li>
        <li>1/30 @ Columbia City Theather w/ Mommy Long Legs, Boyfriends</li>
        <li>1/22 @ Real Art Tacoma (Tacoma) w/ Free Throw, Dude York</li>
      </ul>

      <h3>2015</h3>
      <ul>
        <li>12/18 @ Neumos Psychedelic Holiday Freakout w/ No Age, SSDD</li>
        <li>12/12 @ The Spare Room w/ Kinski, Deep Fried Boogie Band</li>
        <li>12/11 @ The Funhouse w/ Thee Sgt. Major III, Sayonara</li>
        <li>11/14 @ The Know (PDX) w/ Pale Angels, Hurry Up, Honey Bucket</li>
        <li>
          11/13 @ Funhouse (Record Release) w/ Stickers, Boyfriends, Mom Butt (
          <a href="http://seattlemusicinsider.com/2015/11/17/wimps-celebrate-release-of-suitcase-at-the-funhouse/">
            pics
          </a>
          )
        </li>
        <li>
          10/03 @ Lose Yr Mind Fest (PDX) w/ No Age, Gooch Palms, Tijuana
          Panthers
        </li>
        <li>10/02 @ Chop Suey Childbirth, Universe People, Mommy Long Legs</li>
        <li>08/29 @ Watercooler (Boise) w/ Bearcubbin, Fox Alive, Pony Time</li>
        <li>08/28 @ Baby Bar (Spokane) w 66beat, Phlegm Fatale, Pony Time</li>
        <li>
          08/27 @ SAM w/ Pony Time(
          <a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=7&cad=rja&uact=8&ved=0CD8QtwIwBmoVChMIlaDT6rrkyAIVzSuICh1BGwSh&url=http%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DeP4wBXs98ME&usg=AFQjCNGPjS6HqVRO73sYxiHkMBrwyguWbA&sig2=65fBYalipDGVlWfOmfiUpw&bvm=bv.106130839,d.cGU">
            video
          </a>
          |
          <a href="http://www.popthomology.com/2015/08/photos-video-wimps-pony-time-bill-and.html">
            pics
          </a>
          )
        </li>
        <li>08/21 @ Musicfest NW (Portland) w/ Fucked Up, And And And</li>
        <li>08/15 @ The Shanty Tavern (Eureka) w/ Monster Women, Pony Time</li>
        <li>08/14 @ Hemlock (SF) w/ Male Gaze, Pony Time</li>
        <li>08/13 @ Non plus ultra (LA) w/ Upset, Pony Time</li>
        <li>08/12 @ Vinyl Bar (Oakland) w/ Stalls, Pieces</li>
        <li>08/11 @ The Colony (Sacramento) w/ Blahns, Pony Time</li>
        <li>08/10 @ Johnny Bs (Medford) w/ Iconoplasty, Pony Time</li>
        <li>08/09 @ SMMR BMMR (PDX) w/ White Mystery, Guantanamo Baywatch</li>
        <li>
          08/08 @ Pizza fest w/ White Mystery (
          <a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=3&cad=rja&uact=8&ved=0CCgQtwIwAmoVChMIwtWrjrTkyAIViKOICh3doArk&url=http%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DbiaRBg4xKB8&usg=AFQjCNEwBJNz-rLiC1HSuIPFlHqRwCPN7Q&sig2=TBl6LSUqZUneIKGXRBRObA&bvm=bv.106130839,d.cGU">
            video
          </a>
          |
          <a href="https://www.flickr.com/photos/mariannesp/sets/72157656886443330">
            pics
          </a>
          )
        </li>
        <li>
          07/16 @ at Chop Suey w/ Spits, Gazebos (
          <a href="http://blog.kexp.org/2015/07/20/live-review-the-spits-with-wimps-and-gazebos-at-chop-suey-71615/">
            review
          </a>
          |
          <a href="https://www.flickr.com/photos/kexp/sets/72157655636696948/">
            pics
          </a>
          )
        </li>
        <li>07/11 @ West Seattle Fest</li>
        <li>06/27 @ Barboza w/ Eyelids, Phantom Ships</li>
        <li>06/26 @ Frontier Room (PDX) w/ Eyelids, Phantom Ships</li>
        <li>06/06 @ KEXP Community Partnership @ Sunset</li>
        <li>05/09 @ The Future is 0</li>
        <li>
          05/08 @ Rudy's Barber Shop (
          <a href="http://www.popthomology.com/2015/05/photos-wimps-rudys-barbershop-opening.html">
            review
          </a>
          )
        </li>
        <li>
          04/18 @ Office Space w/ So Pitted, Dude York, Underpass, #Tits (
          <a href="https://www.flickr.com/photos/xdo_the_strandx/sets/72157649964749013">
            pics
          </a>
          )
        </li>
        <li>04/01 @ The Crocodile w/ Jeff the Brotherhood</li>
        <li>03/28 @ Turn Turn Turn (PDX) w/ G. Green, Landlines, Bricks</li>
        <li>03/21 @ New Fronteirs (Tacoma)</li>
        <li>02/21 @ Black Lodge w/ Nude Beach, Divers, Vats</li>
        <li>02/19 @ Sunset w/ Coleen Green, Sonny 7 the Sunsets</li>
        <li>02/14 @ Highline w/ Pierced Arrows, SSDD</li>
        <li>01/25 @ Blue Moon w/ Woolen Men, G.Green</li>
        <li>01/24 @ 4th Ave (oly) w/ Deep Creep, Hot Cops, Skates</li>
        <li>01/23 @ Bunk Bar (Portland) w/ Woolen Men, G. Green</li>
        <li>01/10 @ BlackLodge w/Chain & the Gang</li>
      </ul>

      <h3>2014</h3>
      <ul>
        <li>12/12 @ Highline w/ The Dumps, Warning Danger</li>
        <li>10/23 @ Sunset w/ Tripwires, Eyelids</li>
        <li>09/22 @ Neumos w/ The Aislers Set, Childbirth</li>
        <li>09/20 @ Hollow Earth Radio w/ Acapulco Lips, Big Crux</li>
        <li>
          09/13 @ The Copper Owl (Victoria) w/ Maniac, The Flintettes, Minto
        </li>
        <li>09/12 @ Biltmore Cabaret (Vancouver) w/ Flintettes, Maniac</li>
        <li>08/30 @ Werewolf Vacation</li>
        <li>08/16 @ Total Feset (MT)</li>
        <li>08/15 @ Mural Ampitheater (SEA) KEXP Concerts at the Mural</li>
        <li>08/13 @ Hemlock w/ Big Tits, Mane</li>
        <li>08/12 @ Ham & Eggs (LA) w/ Gal Pals</li>
        <li>08/11 @ Tower Bar w/ Bat Lords, The Burdens</li>
        <li>08/10 @ House Party w/ Cumstain, Corners, Shark Toys</li>
        <li>08/09 @ Night Light (OAK)</li>
        <li>
          08/08 @ The Bus Stop (STK) w/ Monster Treasure, Beerwine, Kismit Aura
        </li>
        <li>08/07 @ Bunk Bar (PDX) w/ Lunch, the Rad </li>
        <li>
          08/03 @ Vibrations Fest (
          <a href="http://www.popthomology.com/2014/08/photos-fest-review-wimps-cairo-presents.html">
            review
          </a>
          )
        </li>
        <li>07/23 @ Cha Cha Block Party</li>
        <li>07/12 @ Addaball Records</li>
        <li>06/21 @ Highline w/ Posse (Band Family Feud!)</li>
        <li>05/31 @ Bob's Java Jive w/ Survival Knife (Tacoma)</li>
        <li>05/30 @ Inlander Fest (Spokane)</li>
        <li>05/25 @ SF Popfest w/ Terry Malts, Lunchbox, Zebras (SF)</li>
        <li>05/24 @ Witch House w/ Zig Zags (Sacramento)</li>
        <li>05/23 @ Casa Sandanista w/ Violence Creeps, Puzzled (Oakland)</li>
        <li>05/10 @ Doe Bay Spring Festival</li>
        <li>
          05/01 @ Highligh w/ Coconut Coolouts, Unnatural Helpers, Deep Creeps,
          Fe Fi Fo Fums
        </li>
        <li>04/26 @ Signed fest (Bellingham) w/ Chastity Belt, Dude York</li>
        <li>04/25 @ the vera project w/ so pitted and Bleeding Rainbow</li>
        <li>04/12 @ heartland w/ the dumps, darling chemicalia</li>
        <li>03/14 @ waids w/ sad horse</li>
        <li>03/08 @ Victory w/ Marriage and Cancer</li>
        <li>02/15 @ Highline </li>
        <li>02/01 @ Java Jive w/ Grave Babies</li>
        <li>01/31 @ Victory w/ Mantles</li>
        <li>01/10 @ Cairo Art Gallery Expo 81</li>
        <li>01/03 @ Chop Suey w/ The Spits and Sex Crime</li>
      </ul>

      <h3>2013</h3>
      <ul>
        <li>12/13 @ Victory w/ Pelvis Wrestlies, Pony Time</li>
        <li>11/01 @ Record Room (PDX) w/ Western Hymn</li>
        <li>
          10/18 @ Chop Suey w/ Hunx and his Punx, Coconut Coolouts (
          <a href="http://www.seattleweekly.com/music/949443-129/hunx-butt-audience-band-punk-punx">
            review
          </a>
          |
          <a href="http://www.popthomology.com/2013/10/photos-videos-show-review-hunx-his-punx.html">
            pics
          </a>
          )
        </li>
        <li>10/05 @ Highline w/ Pony Time, The Babies, Chastity Belt</li>
        <li>09/20 @ Heartland w/ Prissey Clerks, Woolen Men</li>
        <li>09/07 @ The Know (PDX) w/ Therapists</li>
        <li>08/24 @ Portland - Record Room w/ Hurry Up, Woolen Men</li>
        <li>
          08/23 @ Boise - The Shredder w/ First Borns, Gorcias, Fort Harrison
        </li>
        <li>08/22 @ SLC - Burt's Tiki Lounge w/ Baby Ghosts</li>
        <li>08/21 @ Denver at Rhinoceropolis w/ Pizza Time, Best Creeps</li>
        <li>08/20 @ Omaha, NE - Slowdown w/ Coaxed </li>
        <li>08/19 @ Kansas City, MI - Blarney Stone</li>
        <li>08/18 @ Memphis, TN - Buckaneer</li>
        <li>08/17 @ Nashville, TN - Foobar</li>
        <li>08/16 @ Asheville, NC - Odditorium w/ Hot Mess Monster + more</li>
        <li>08/15 @ Harrisonburg, VA- Crayola</li>
        <li>
          08/14 @ Baltimore - GW w/ Goldenwest, Widows Watch, Blackwolf Beach
        </li>
        <li>08/13 @ Philly - Kung Fu Necktie</li>
        <li>
          08/12 @ New York - Cakeshop w/ Numerators, Butter the Children, Wierd
          Fantasy
        </li>
        <li>08/11 @ Kingston, NY - BSP Lounge w/ Kitty Little</li>
        <li>08/10 @ New York - Silent Barn, East Village Radio</li>
        <li>
          08/08 @ Detroit - PJ's Lager House w/ Tyvek, Sros Lords, Growing Pains
        </li>
        <li>08/07 @ Chicago - Wally's World</li>
        <li>
          08/06 @ Milwaukee - Quarters Rock N Roll Palace w/ Heartthrob, Pressy
          clerks
        </li>
        <li>08/05 @ Minneapolis - Hexagon Bar w/ Gospel Gossip</li>
        <li>08/04 @ Fargo - Aquarium</li>
        <li>
          08/03 @ Missoula - Ole Beck VFW 209 w/ The Low Ballers, Cain & Fable
        </li>
        <li>08/02 @ Portland - SMMR BMMR </li>
        <li>07/28 @ Chop Suey - Off the Block Party </li>
        <li>06/30 @ Brotherhood (OLY) w/ Western Hymn</li>
        <li>06/28 @ The Comet w/ Indian Wars</li>
        <li>
          06/22 @ 1234 Go! (Oak) w/ Courtney and the Crushers, Straight Crimes
        </li>
        <li>06/21 @ Luigis (SAC) w/ Satan Wriders, Nacho Business</li>
        <li>05/14 @ Record Room (PDX) w/ Big Black Cloud, The Bugs</li>
        <li>04/25 @ Neumo's w/ The Thermals, La Luz</li>
        <li>04/12 @ Slabtown Bender (PDX)</li>
        <li>04/06 @ Blue Moon w/ Chastity Belt, Dude York</li>
        <li>
          03/29 @ The Black Lodge w/ The Fuzz (Ty Segall), Unnatural Helpers
        </li>
        <li>
          03/16 @ The Penumbra Beer Fest w/ Tacocat, Champagne Champagne,
          Pleasureboaters
        </li>
        <li>02/16 @ The Crocodile w/ The Orwells, Grim Smiley</li>
        <li>
          02/09 @ Columbia City Theatre w/ Eighteen Individual Eyes, Let's Get
          Lost (<a href="http://www.youtube.com/watch?v=klq8IL-AcMI">vid</a>)
        </li>
        <li>
          01/26 @ Black Lodge w/ Trashies, Stickers, Big Eyes (
          <a href="http://www.flickr.com/photos/mariannesp/sets/72157632629401522/">
            pics
          </a>
          )
        </li>
        <li>
          01/12 @ Northern w/ Maxines (
          <a href="http://krecs.com/photos/maxines-at-northern-olympia/">
            pics
          </a>
          )
        </li>
        <li>01/11 @ The Know w/ Woolen Men</li>
        <li>
          01/05 @ Tractor w/ Don't Talk to the Cops (KEXP Audioasis Benefit
          Show) (
          <a href="http://www.flickr.com/photos/kexp/8370068738/in/set-72157632489809747/">
            pics
          </a>
          )
        </li>
      </ul>

      <h3>2012</h3>
      <ul>
        <li>12/31 @ Comet w/ Haunted Horses, Monogamy Party</li>
        <li>12/22 @ Chop Suey w/ The Pharmacy</li>
        <li>12/15 @ Live on KEXP's Audioasis (6:30pm)</li>
        <li>10/20 @ Funhouse w/ Pleasureboaters</li>
        <li>
          10/07 @ Neptune w/ Thee Oh Sees, Sic Alps (
          <a href="http://lineout.thestranger.com/lineout/archives/2012/10/04/wimps-things-could-be-worse">
            preview1
          </a>
          <a href="http://www.thestranger.com/seattle/underage/Content?oid=14913394">
            preview2
          </a>
          |
          <a href="http://www.flickr.com/photos/mariannesp/sets/72157631735507952/">
            pics
          </a>
          )
        </li>
        <li>10/06 @ House Party w/ Ubu Roy, Shark Toys</li>
        <li>10/05 @ Old Redmond Firehouse w/ DTTTC</li>
        <li>
          09/28 @ Funhouse w/ Unnatural Helpers, Stickers (
          <a href="http://lineout.thestranger.com/lineout/archives/2012/10/02/pizza-shirt-guy-some-shows-i-saw-on-friday#more">
            review
          </a>
          )
        </li>
        <li>09/15 @ Fuck Dungeon (Davis) w/ Godspeed 209, Yi, Naomi Punk</li>
        <li>
          09/14 @ Brick and Mortar Club (SF) w/ The Zeros, Midnight Snaxxx
        </li>
        <li>
          09/12 @ Chop Suey w/ Grass Widow, Plaided (
          <a href="http://endlessgreyskies.tumblr.com/post/32004580779/the-wimps-and-grass-widow-at-chop-suey-on#notes">
            pics
          </a>
          )
        </li>
        <li>08/26 @ Cha Cha w/ G. Green, Screature, Universe People</li>
        <li>08/18 @ The Northern (Olympia) w/ The Maxines, guests</li>
        <li>08/17 @ 1234 Go! Records (Oakland) w/ The Mallard, Uzi Rash</li>
        <li>08/16 @ Thee Parkside (SF) w/ The Mallard, Big Drag</li>
        <li>08/15 @ Burger Records (Fullerton) w/ Shark Toys, Magic Trash</li>
        <li>08/14 @ Bar 11 (SD) w/ Teenage Burritos, The Bertos</li>
        <li>
          08/13 @ C.A.F.E. Infoshop (Fresno) w/ Glamour Tronic, Sci-Fi Caper,
          Werebear
        </li>
        <li>08/12 @ Clark's House (Reno) w/ Surf Curse</li>
        <li>
          08/11 @ Hemlock (SF) w/ Dikes of Holland, Lovely Bad Things, Golden
          Pelicans
        </li>
        <li>08/10 @ The Townhouse (Sacramento) w/ Bad Daddies, Godspeed 209</li>
        <li>08/09 @ Backspace (PDX) w/ Pink Slime, Little Pilgrims</li>
        <li>08/02 @ Funhouse (Pizzafest) w/ Shannon and the Clams</li>
        <li>
          07/19 @ Crocodile w/ The Intelligence, Unnatural Helpers, DREAMSALON (
          <a
            rel="noreferrer"
            href="http://www.flickr.com/photos/mariannesp/sets/72157630674309770/"
          >
            pics
          </a>
          &nbsp;|&nbsp;
          <a href="http://lineout.thestranger.com/lineout/archives/2012/07/20/wimps-pass-the-pro-stage-challenge">
            review1
          </a>
          ,&nbsp;
          <a href="http://gtr1ab.wordpress.com/2012/07/19/solid-state-2/">
            review2
          </a>
          ,&nbsp;
          <a href="http://open.salon.com/blog/mariannesp/2012/07/22/photos_the_intelligence_everybodys_got_it_easy_but_me_album_release_show_w_unnatural_helpers_dream_salon_wimps_seattle_71912">
            review3
          </a>
          )
        </li>
        <li>
          07/09 @ Funhouse w/ The Mallard, Stickers, Christmas (
          <a
            rel="noreferrer"
            href="http://www.flickr.com/photos/invisiblehour/sets/72157630510142718/"
          >
            pics
          </a>
          )
        </li>
        <li>07/08 @ Doug Fir (PDX) w/ The Mallard</li>
        <li>06/08 @ Rendezvous with Wax Idols, White Lung</li>
        <li>06/09 @ Logan's Pub (Victoria, BC) w/ The Maxines</li>
        <li>07/08 @ Doug Fir (PDX) w/ The Mallard</li>
        <li>
          06/09 @ Earthquake Shake w/ The Maxines, Bad Channels, Teledrome
          (Victoria)
        </li>
        <li>06/08 @ Rendezvous with Wax Idols, White Lung</li>
        <li>05/04 @ Funhouse w/ Grave Babies, Posse, Haunted Horses</li>
        <li>
          04/28 @ Sonic Reducer (KEXP) (
          <a
            rel="noreferrer"
            href="http://soundcloud.com/rachelratner/wimps-live-on-kexp"
            target="_blank"
          >
            listen!
          </a>
          )
        </li>
        <li>04/14 @ Rendezvous with Broken Nobles, Ziskis, Fuzzy Cloaks</li>
        <li>04/05 @ Squeak and Squawk Fest (tacoma)</li>
        <li>
          03/31 @ HER Magma Fest (
          <a
            rel="noreferrer"
            href="http://www.flickr.com/photos/invisiblehour/sets/72157629356092818/with/7037354143/"
            target="_blank"
          >
            pics
          </a>
          )
        </li>
        <li>
          02/10 @ Black Lodge w/ SPURM, Uzi Rash, Unnatural Helpers (
          <a rel="noreferrer" href="http://bit.ly/zvAKb9" target="_blank">
            show preview
          </a>
          )
        </li>
        <li>02/09 @ UW w/ Orca Team, Feet</li>
        <li>01/13 @ Rendezvous w/ MK Speedial, Ships</li>
        <li>
          01/07 @ Funhouse w/ Big Eyes, The Burns, Jaguar Paw (
          <a
            rel="noreferrer"
            href="http://www.flickr.com/photos/invisiblehour/sets/72157628787852155/"
          >
            pics
          </a>
          )
        </li>
      </ul>

      <h3>2011</h3>
      <ul>
        <li>12/09 @ Rendezvous w/ Shimmering Stars, Orca Team</li>
        <li>11/19 @ The Alleyway w/ Perpetual Ritual, Little Pilgrims</li>
        <li>
          11/10 @ Funhouse with Wax Idols, Terry Malts (
          <a href="http://finestkiss.wordpress.com/2011/11/13/wax-idols-terry-malts-and-wimps-at-the-funhouse/">
            show review
          </a>
          &nbsp;|&nbsp;
          <a href="http://www.youtube.com/watch?v=h_4hfViHwoc">vid</a>)
        </li>
        <li>
          10/29 @ Keith's Party (
          <a href="http://lineout.thestranger.com/lineout/archives/2011/10/31/the-people-with-the-masks-halloween-2011">
            show review
          </a>
          )
        </li>
      </ul>
    </div>
  );
}
