export default function Shows({ home }) {
  return (
    <section className="shows">
      <h2>Record Release Shows</h2>
      <ul>
        <li>
          Friday, October 13th, @ The Clockout (SEA) w/ Hell Baby, Debt Rag, Rat
          Queen (
          <a className="link" target="_blank" href="bit.ly/45J9up1">
            Tickets
          </a>
          )
        </li>
        <li>
          Saturday, November 4th, @ The Fixin To (PDX) w/ Mini Blinds, Scorch (
          <a
            target="_blank"
            href="www.thefixinto.com/event-details/wimps-mini-blinds-scorch"
            className="link"
          >
            Tickets
          </a>
          )
        </li>
      </ul>
      <h4>
        <a href="http://www.bandsintown.com/wimps/start_tracking">
          Notify me when wimps come to town
        </a>
      </h4>
      {!home && (
        <h4 id="pastShowsBtn">
          <a href="/pastshows">See Past Shows</a>
        </h4>
      )}
    </section>
  );
}
